import tools from "../../../extends/tools";
import apis from "../../common/js/api2";
export default {

    printForm() {
        this.printSimpleForm = true;
    },
    printLodopExit() {
        this.printSimpleForm = false;
    },

    init() {
        //获取数据源
        apis.paymentSource().then(res => {
            if (tools.msg(res, this)) {
                for (let i in res.data) {
                    if (res.data[i]) {
                        this.$set(this.source, i, res.data[i]);
                    } else {
                        this.$set(this.source, i, []);
                    }
                }
            }
        }).catch(err => {
            tools.err(err, this);
        });
        //如果是编辑，则获取详情
        let id = this.$route.query.id;
        if (id > 0) {
            //查看详情禁止编辑
            if (this.$route.query.read == 1) {
                this.isRead = true;
            }
            apis.paymentDetail({
                id: id
            }).then(res => {
                if (tools.msg(res, this)) {
                    this.initData(res.data);
                }
            }).catch(err => {
                tools.err(err, this);
            });
        }
    },
    initData(data) {
        let goods = [];
        data.details.forEach((item, key) => {
            for (let i in item) {
                if (i === 'actual' || i === 'amount' || i === 'discount') {
                    item[i] = tools.setPrice(item[i]);
                }
            }

            goods.push(item);
        });
        this.goodsList = goods;
        console.log(this.goodsList);
        this.saveData = {
            number: data.number,
            supplier_id: data.supplier_id,
            user_id: data.user_id,
            business_date: data.business_date,
            additional_amount: tools.setPrice(data.additional_amount),
            settlement_method: data.settlement_method,
            remarks: data.remarks,
            payment_amount: tools.setPrice(data.payment_amount),
            enclosure: data.enclosure,
            goods: goods,
            status: data.status
        }

        this.showData = data;
        this.showData.total = tools.setPrice(this.showData.total);
        this.changeSum();
    },
    changeSum() {
        this.saveData.sum1 = 0;
        this.saveData.sum2 = 0;
        this.saveData.sum3 = 0;
        this.goodsList.forEach((item, key) => {
            this.saveData.sum1 += parseFloat(item.amount);
            this.saveData.sum2 += parseFloat(item.discount);
            this.saveData.sum3 += parseFloat(item.actual);
        });
        this.saveData.sum1 = tools.toDecimal2(this.saveData.sum1);
        this.saveData.sum2 = tools.toDecimal2(this.saveData.sum2);
        this.saveData.sum3 = tools.toDecimal2(this.saveData.sum3);
    },
    cancel() {
        this.$router.push({
            path: '/payment'
        });
    },
    exportData() {

    },
    remove() {
        let id = this.$route.query.id;
        this.$confirm('确定要删除吗?', '删除确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            apis.paymentDelete({ id: id }).then((info) => {
                if (info.code == 200) {
                    this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });

                    this.$router.push({
                        path: "/payment"
                    })
                } else {
                    this.$message({
                        type: 'error',
                        message: info.msg
                    });
                }
            })
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        });
    },
    copyRecord() {
        let id = this.$route.query.id;
        this.$router.push({
            path: '/payment/add',
            query: {
                id: id,
                is_copy: 1
            }
        });
    },
    edit() {
        let id = this.$route.query.id;
        this.$router.push({
            path: '/payment/add',
            query: {
                id: id
            }
        });
    },
    apply(status) {
        let id = this.$route.query.id;
        apis.paymentApply({
            id: id,
            status: status
        }).then(res => {
            tools.msg(res, this, 1);
        }).catch(err => {
            tools.err(err, this);
        });
    },
}