import tools from "../../../extends/tools";
export default {
    //打印
    printSimpleForm: false,

    dataInit: null,
    showData: {},
    goodsList: [],
    baseUrl: tools.baseURL(),
    uploadUrl: tools.baseURL() + 'api/admin/file',
    authorizationBearer: tools.getToken(),
    createShareFlag: false,
    enclosure: [],
    sumData: {
        sumNum: 0,
        sumPrice: 0,
        allSumPrice: 0,
    },
    saveDataInit: null,
    //
    source: {},
    isRead: false,
    saveData: {
        warehouse_id: '',
        supplier_id: '',
        user_id: tools.me(),
        business_date: tools.now(),
        additional_amount: 0,
        settlement_method: '',
        remarks: '',
        payment_amount: '',
        enclosure: [],
        goods: [],
        status: 0,
        number: '',
        sum1: 0,
        sum2: 0,
        sum3: 0
    }
}